import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div className="bg-metalife-500 text-white p-2 flex">
      <div className="grow">
        <img alt="Logo" src="/logo.png" width={"120px"} />
      </div>
      <div>
        <NavLink to={"/logout"}>Sair</NavLink>
      </div>
    </div>
  );
}

export default Header;
