import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import Clientes from "./pages/clientes";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import Orcamento from "./pages/orcamento";

const router = createBrowserRouter([
  {
    path: "/",
    Component: Login,
  },
  {
    path: "dashboard",
    // loader: protectedLoader,
    Component: Dashboard,
  },
  {
    path: "clientes",
    // loader: protectedLoader,
    Component: Clientes,
  },
  {
    path: "orcamentos/:id",
    // loader: protectedLoader,
    Component: Orcamento,
  },
  {
    path: "logout",
    Component: Login,
    loader() {
      localStorage.setItem("token", "");
      return redirect("/");
    },
  },
]);


function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
