function CardDefault(props) {
  return (
    <div className={"bg-white rounded-lg " + props.className}>
      {props.titulo && (
        <div className="p-2 bg-metalife-500 rounded-t-lg text-white items-center">
          <div className="flex">
            <div className="grow truncate">{props.titulo}</div>
            <div>
              {props.botoes &&
                props.botoes.map((botao) => {
                  return botao;
                })}
            </div>
          </div>
        </div>
      )}
      {props.children}
    </div>
  );
}

export default CardDefault;
