import { BsFillTrashFill } from "react-icons/bs";
import CardDefault from "../../components/Cards/CardDefault";

function ResumoCarrinho(props) {
  return (
    <div>
      <div className="flex mx-2 items-center bg-gray-200 rounded-lg px-2 py-2 mb-3">
        <div className="grow"> Total sem desconto:</div>
        <div className="  rounded-r">
          {props.orcamento.valorSemDesconto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </div>
      </div>
      <div className=" mx-2  bg-gray-200 rounded-lg px-2 py-2 mb-3 flex">
        <div className="grow">Desconto: {props.orcamento.desconto}% </div>
        <input
          type="range"
          min="0"
          max="25"
          value={props.orcamento.desconto}
          onChange={(e) => props.setOrcamentoDesconto(e.target.value)}
        ></input>
      </div>
      <div className="flex mx-2 items-center bg-gray-200 rounded-lg px-2 py-2 mb-3">
        <div className="grow"> Total com desconto:</div>
        <div className="  rounded-r">
          {props.orcamento.valorComDesconto.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </div>
      </div>
      <div className="">
        {props.orcamento.produtos.map((item, i) => {
          return (
            <CardDefault className="m-2" titulo={item.nome} key={item.id}>
              <div className="flex p-2 items-center  text-xs">
                <div
                  className={
                    "py-2 px-4 rounded-lg text-white " +
                    (item.quantidade === 1
                      ? "bg-red-500 cursor-not-allowed text-sm px-2"
                      : "bg-metalife-500 ")
                  }
                  onClick={() =>
                    item.quantidade > 1
                      ? props.setaQuantidade(i, item.quantidade - 1)
                      : props.removeProduto(item)
                  }
                >
                  {item.quantidade === 1 ? <BsFillTrashFill /> : "-"}
                </div>
                <div className="py-2 px-4 rounded-lg bg-gray-500 text-white">
                  {item.quantidade}
                </div>
                <div
                  className="py-2 px-4 rounded-lg bg-metalife-500 text-white"
                  onClick={() => props.setaQuantidade(i, item.quantidade + 1)}
                >
                  +
                </div>
                <div className="px-1">x</div>
                <div className="py-2 px-4 rounded-lg bg-gray-500 text-white">
                  {item.valorComDesconto.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </div>
                <div className="px-1">=</div>
                <div className="py-2 px-4 rounded-lg bg-gray-500 text-white">
                  {(item.valorComDesconto * item.quantidade).toLocaleString(
                    "pt-br",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  )}
                </div>
              </div>
            </CardDefault>
          );
        })}
      </div>
    </div>
  );
}

export default ResumoCarrinho;
