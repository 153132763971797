import { useState } from "react";
import CardAlert from "../components/Cards/CardAlert";
import CardDefault from "../components/Cards/CardDefault";
import Input from "../components/Forms/Input";
import Header from "../components/Layout/Header";
import { getAPIClient } from "../services/axios";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formErroMessage, setFormErroMessage] = useState("");

  const loginHandler = async () => {
    setFormErroMessage("");
    const apiClient = getAPIClient();
    const login = await apiClient.post("/usuarios/login", formData);

    if (login && login.sucesso) {
      localStorage.setItem("token", login.dados.api_token);
      window.location.href = "/dashboard";
    } else {
      setFormErroMessage(login.mensagem);
    }
  };

  const token = localStorage.getItem("token");
  if (token && token.length > 0) {
    window.location.href = "/dashboard";
  }

  return (
    <div>
      <Header />
      <div className="flex justify-center w-full">
        <CardDefault titulo="Login" className="m-4 md:w-1/4">
          <CardAlert mensagem={formErroMessage} />
          <div className="p-2">
            <form className="w-full">
              <Input
                label={"E-mail"}
                name={"email"}
                placeholder="E-mail"
                formData={formData}
                setFormData={setFormData}
              />
              <Input
                label={"Senha"}
                name={"password"}
                placeholder="Senha"
                type={"password"}
                formData={formData}
                setFormData={setFormData}
              />

              <button
                onClick={() => loginHandler()}
                className="flex-shrink-0 bg-green-500 hover:bg-green-700 border-green-500 hover:border-green-700 text-sm border-4 text-white py-1 px-2 rounded"
                type="button"
              >
                Entrar
              </button>
            </form>
          </div>
        </CardDefault>
      </div>
    </div>
  );
}

export default Login;
