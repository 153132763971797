function Titulo(props) {
  return (
    <div className="bg-gray-600 text-white p-2 flex items-center">
      <div className="grow truncate">{props.titulo}</div>
      {props.botoes &&
        props.botoes.map((botao) => {
          return botao;
        })}
    </div>
  );
}

export default Titulo;
