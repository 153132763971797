function Paginacao(props) {
  if (props.paginacao.ultima_pagina === 1) return <div></div>;
  return (
    <div className="flex mx-4">
      <div className="grow">
        {props.params.page > 0 && (
          <div>
            <div
              onClick={() =>
                props.setParams({
                  ...props.params,
                  page: props.params.page - 1,
                })
              }
            >
              Anterior
            </div>
          </div>
        )}
      </div>
      <div className="grow">
        {props.paginacao.pagina_atual} de {props.paginacao.ultima_pagina}
      </div>
      <div>
        <div
          onClick={() =>
            props.setParams({ ...props.params, page: props.params.page + 1 })
          }
        >
          Próximo
        </div>
      </div>
    </div>
  );
}

export default Paginacao;
