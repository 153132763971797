import axios from "axios";
// import { parseCookies } from "nookies";

export function getAPIClient(ctx) {
  // const api = axios.create({ baseURL: "http://localhost:8081/api" });
  const api = axios.create({ baseURL: "https://backend.metalife.com.br/api" });

  api.interceptors.request.use((config) => {
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async (error) => {
      if (error.response && error.response.data) return error.response.data;
    }
  );

  // async function getNewToken() {
  //   const cookies = nookies.get(ctx);
  //   return;
  // }

  // function redirectToLogin() {}

  api.defaults.headers["Authorization"] = `Bearer ${
    localStorage.getItem("token") || ""
  }`;

  return api;
}
