function Input(props) {
  return (
    <div>
      <label className="block color-metalife-500 text-xs" htmlFor={props.name}>
        {props.label}
      </label>
      <input
        onChange={(e) =>
          props.setFormData({ ...props.formData, [props.name]: e.target.value })
        }
        type={props.type}
        className="appearance-none w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none border-b border-teal-500 mb-2"
        placeholder={props.placeholder}
        value={props.formData[props.name]}
      />
    </div>
  );
}

export default Input;
