import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Input from "../components/Forms/Input";
import Carregando from "../components/Layout/Carregando";
import Header from "../components/Layout/Header";
import Paginacao from "../components/Layout/Paginacao";
import Titulo from "../components/Layout/Titulo";
import { getAPIClient } from "../services/axios";

function Clientes() {
  let navigate = useNavigate();
  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);

  const [carregando, setCarregando] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [paginacao, setpaginacao] = useState();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    sort: "nome",
    order: "asc",
    nome: queryParams.get("nome"),
  });

  useEffect(() => {
    const fetchClientes = async () => {
      setClientes([]);
      setCarregando(true);
      const apiClient = getAPIClient();
      const clientes = await apiClient.get("/totvs/clientes", {
        params: params,
      });

      if (!clientes.sucesso) console.log(clientes?.mensagem);
      setClientes(clientes.dados);
      setpaginacao(clientes.paginacao);
      setCarregando(false);
    };
    fetchClientes();
  }, [params]);

  const documento = (cliente) => {
    if (cliente.cpf) return "CPF: " + cliente.cpf;
    if (cliente.cnpj) return "CNPJ: " + cliente.cnpj;
    if (cliente.rg) return "RG: " + cliente.rg;
    return "";
  };

  const buscar = async () => {
    if (params.nome !== "") {
      return navigate("/clientes?nome=" + params.nome);
    }
  };

  return (
    <div>
      <Header />
      <Titulo titulo="Clientes" />
      <Carregando carregando={carregando} />

      {clientes.length > 0 && (
        <div className="container mx-auto mt-2">
          <div className="mx-4" onClick={() => buscar()}>
            <Input
              name={"nome"}
              label="Buscar cliente"
              setFormData={setParams}
              formData={params}
            />
          </div>
          <div className="">
            <div>
              {clientes.map((cliente) => {
                return (
                  <NavLink to={"/orcamentos/" + cliente.id}>
                    <div
                      key={cliente.id}
                      className="my-2 mx-4 bg-gray-100 rounded"
                    >
                      <div className="text-sm bg-gray-300 rounded-t font-bold py-1 px-2">
                        <div className="truncate">{cliente.nome}</div>
                      </div>
                      <div className="text-sm py-1 px-2">
                        {documento(cliente)} | {cliente.municipio}/
                        {cliente.estado}{" "}
                      </div>
                    </div>
                  </NavLink>
                );
              })}
            </div>
            {paginacao && (
              <Paginacao
                paginacao={paginacao}
                params={params}
                setParams={setParams}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Clientes;
