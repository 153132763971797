function Carregando(props) {
  if (props.carregando)
    return (
      <div className="bg-metalife-800 text-white p-2 flex text-xs">
        {props.mensagem ? props.mensagem : "Carregando..."}
      </div>
    );
}

export default Carregando;
