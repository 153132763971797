function CardAlert(props) {
  if (!props.mensagem) return;
  return (
    <div className={"bg-red-200 text-red-900 p-2 text-sm " + props.className}>
      {props.mensagem}
    </div>
  );
}

export default CardAlert;
