import { useEffect, useState } from "react";
import Carregando from "../../components/Layout/Carregando";
import Paginacao from "../../components/Layout/Paginacao";
import Titulo from "../../components/Layout/Titulo";
import { getAPIClient } from "../../services/axios";

function ProdutosBuscar(props) {
  const [carregando, setCarregando] = useState(false);
  const [produtos, setProdutos] = useState([]);
  const [paginacao, setpaginacao] = useState();
  const [mostrarModal, setMostrarModal] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    sort: "nome",
    order: "asc",
  });

  useEffect(() => {
    const fetchProdutos = async () => {
      setProdutos([]);
      setCarregando(true);
      const apiClient = getAPIClient();
      const produtos = await apiClient.get("/orcamentos/produtos", {
        params: params,
      });

      if (!produtos.sucesso) console.log(produtos?.mensagem);
      setProdutos(produtos.dados);
      setpaginacao(produtos.paginacao);
      setCarregando(false);
    };
    fetchProdutos();
  }, [params]);

  return (
    <div>
      {mostrarModal && (
        <div className="absolute top-0 w-full h-full bg-gray-500">
          <Titulo
            titulo="Buscar produto"
            botoes={[
              <div
                key="fechar"
                className="bg-white text-metalife-500 rounded-lg px-2 py-1 text-xs"
                onClick={() => setMostrarModal(false)}
              >
                Fechar
              </div>,
            ]}
          />
          <Carregando
            carregando={carregando}
            mensagem="Carregando produtos..."
          />

          <div className="">
            <div>
              {produtos.map((produto) => {
                const noCarrinho = props.orcamento.produtos.filter(function (
                  item
                ) {
                  return item.id === produto.id;
                });

                if (noCarrinho.length > 0) {
                  return (
                    <div
                      onClick={() => props.removeProduto(produto)}
                      key={produto.id}
                      className="my-2 mx-4 rounded"
                    >
                      <div className="text-sm bg-metalife-300 rounded-t flex">
                        <div className="truncate grow py-1 px-2 font-bold">
                          {produto.nome}
                        </div>
                        <div className=" bg-metalife-100 py-1 px-2 rounded-r">
                          R${produto.valor}
                        </div>
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    onClick={() => props.adicionaProduto(produto)}
                    key={produto.id}
                    className="my-2 mx-4 bg-gray-100 rounded"
                  >
                    <div className="text-sm bg-gray-300 rounded-t flex">
                      <div className="truncate grow py-1 px-2 font-bold">
                        {produto.nome}
                      </div>
                      <div className="bg-gray-200 py-1 px-2 rounded-r">
                        R${produto.valor}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {paginacao && produtos.length > 0 && (
              <Paginacao
                paginacao={paginacao}
                params={params}
                setParams={setParams}
              />
            )}
          </div>
        </div>
      )}

      {!mostrarModal && (
        <div
          className="m-2 p-2 text-center bg-blue-500 text-white rounded-lg"
          onClick={() => setMostrarModal(true)}
        >
          Buscar produto
        </div>
      )}
    </div>
  );
}

export default ProdutosBuscar;
