import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Forms/Input";
import Header from "../components/Layout/Header";
import Titulo from "../components/Layout/Titulo";

function Dashboard() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    nome: "",
  });

  const buscar = async () => {
    if (formData.nome !== "") {
      return navigate("/clientes?nome=" + formData.nome);
    }
  };

  return (
    <div>
      <Header />
      <Titulo titulo="Dashboard" />

      <div className="p-4 ">Selecione um cliente para criar um orçamento</div>
      <div className="mx-4" onClick={() => buscar()}>
        <Input
          name={"nome"}
          label="Buscar cliente"
          setFormData={setFormData}
          formData={formData}
        />
      </div>
      <div
        className="bg-metalife-500 text-white rounded mx-4 p-2 text-center"
        onClick={() => buscar()}
      >
        Buscar cliente
      </div>
    </div>
  );
}

export default Dashboard;
