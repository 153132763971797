import { useState } from "react";
import ResumoCarrinho from "../components/Carrinho/ResumoCarrinho";
import Header from "../components/Layout/Header";
import Titulo from "../components/Layout/Titulo";
import ProdutosBuscar from "../components/Produtos/ProdutosBuscar";

function Orcamento() {
  const [orcamento, setOrcamento] = useState({
    produtos: [],
    desconto: 0,
    valorSemDesconto: 0,
    valorComDesconto: 0,
  });

  const adicionaProduto = (produto) => {
    const dados = {
      ...produto,
      quantidade: 1,
      desconto: 0,
      valorComDesconto: produto.valor * 1,
    };
    const produtos = [...orcamento.produtos, dados];
    ajustaOrcamento({ produtos: produtos });
  };

  const removeProduto = (produto) => {
    const produtos = orcamento.produtos.filter(function (item) {
      return item.id !== produto.id;
    });
    ajustaOrcamento({ produtos: produtos });
  };

  const setaQuantidade = (i, valor) => {
    const produtos = orcamento.produtos.map(function (produto, index) {
      if (index === i) {
        return {
          ...produto,
          quantidade: valor,
          valorComDesconto: produto.valor * valor,
        };
      } else {
        return produto;
      }
    });
    ajustaOrcamento({ produtos: produtos });
  };

  const setOrcamentoDesconto = (valor) => {
    const produtos = orcamento.produtos.map(function (produto) {
      return {
        ...produto,
        desconto: valor,
        valorComDesconto: produto.valor * (1 - valor / 100),
      };
    });

    ajustaOrcamento({ produtos: produtos, desconto: valor });
  };

  const ajustaOrcamento = (dados) => {
    const produtos = dados.produtos ? dados.produtos : orcamento.produtos;
    const desconto = dados.desconto ? dados.desconto : orcamento.desconto;
    const valorComDesconto = produtos.reduce(
      (valor, item) => valor + item.valorComDesconto * item.quantidade,
      0
    );
    const valorSemDesconto = produtos.reduce(
      (valor, item) => valor + item.valor * item.quantidade,
      0
    );
    setOrcamento({
      ...orcamento,
      produtos: produtos,
      desconto: desconto,
      valorSemDesconto: valorSemDesconto,
      valorComDesconto: valorComDesconto,
    });
  };

  return (
    <div>
      <Header />
      <Titulo titulo="Orcamento" />
      <div className="flex">
        <div className="grow">
          <ProdutosBuscar
            adicionaProduto={adicionaProduto}
            removeProduto={removeProduto}
            orcamento={orcamento}
          />
        </div>
        <div>
          <div
            className={
              "m-2 p-2 px-4 text-center text-white rounded-lg " +
              (orcamento.valorComDesconto > 0
                ? " bg-metalife-500"
                : " bg-gray-400")
            }
          >
            Prosseguir
          </div>
        </div>
      </div>
      <ResumoCarrinho
        orcamento={orcamento}
        setaQuantidade={setaQuantidade}
        adicionaProduto={adicionaProduto}
        removeProduto={removeProduto}
        setOrcamentoDesconto={setOrcamentoDesconto}
      />
    </div>
  );
}

export default Orcamento;
